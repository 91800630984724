var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-right mt-3 mr-2"
  }, [_vm.addnewAddDoc ? _c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal",
      modifiers: {
        "modal": true
      }
    }],
    staticClass: "btn btn-primary btn-sm"
  }, [_vm._v(" " + _vm._s(_vm.$t("add-new")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "table-responsive mt-3 mb-5"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("details")))]), _c('th', [_vm._v(_vm._s(_vm.$t("document")) + " / " + _vm._s(_vm.$t("appendix")))]), _c('th')])]), _c('tbody', [_vm._l(_vm.documents, function (doc, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(doc.description))]), _c('td', [_c('a', {
      attrs: {
        "target": "_blank",
        "href": doc.public_path
      }
    }, [_vm._v(_vm._s(doc.original_filename))])]), _c('td', {
      staticClass: "text-right"
    }, [_vm.addnewAddDoc ? _c('button', {
      staticClass: "btn btn-danger btn-sm",
      on: {
        "click": function ($event) {
          return _vm.removeAttachment(doc);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()])]);
  }), _vm.documents && _vm.documents.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h5', {
    staticClass: "pt-5 pb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e()], 2)])]), _c('b-modal', {
    attrs: {
      "id": "modal",
      "title": "Tambah Dokumen/Lampiran",
      "ok-title": "Simpan",
      "cancel-title": "Batal",
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.storeDocument
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-12",
    class: {
      'has-error': _vm.$v.form.description.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "receiver_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("description2")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.description.$model,
      expression: "$v.form.description.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.description.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.description, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.description.$error && !_vm.$v.form.description.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("description2")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-12 form-group",
    class: {
      'has-error': _vm.$v.document.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("appendix")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.documentUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.documentUrl + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.documentUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("select-copy")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })])]), _vm.$v.document.$error && !_vm.$v.document.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }